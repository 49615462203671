export const EnableLogs = false
// Test Urls starts //
//export const serverUrl = 'https://s20q1.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://s20q1.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://s20q1.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s20q1.mycw.in:8443/'
// Test Urls ends //

// Dev server Urls starts //
//export const serverUrl = 'https://erps-test.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://erps-test.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://identity-test.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://erps-test.mycw.in:8443/'
// Dev server Urls ends //

// Pre prod Urls starts // 
//export const serverUrl = 'https://s360app.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://sapp.mycw.in/services/graphql'
//export const tokenUrl ='https://s360app.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sapp.mycw.in:8443/'
// Pre prod Urls ends //

// demo server Urls starts //
//export const serverUrl = 'https://s2demos.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://sdemos.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://identity-demos.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sdemos.cw.solutions:8443/'
// demo server Urls ends //

//Anandyas Urls starts //
//export const serverUrl = 'https://s2anandyas.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://erp2anandyas.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl ='https://ianandyas.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s2anandyas.cw.solutions:8443/'
//Anandyas Urls end //

// ofc test server Urls starts //
//export const serverUrl = 'https://sofc-test-analytics.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://sofc-test-analytics.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sofc-test-analytics.mycw.in:8443/'
// ofc test server Urls end //

//export const serverUrl = 'https://sofc-test-analytics.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://sofc-test-analytics.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sofc-test-analytics.mycw.in:8443/'

// OFC-Live server Urls starts //
//export const serverUrl = ' '
//export const genericUrl ='https://sofc01.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl = ' https://sauth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sofc01.cw.solutions:8443/'
// OFC-Live server Urls ends //

// ofc latest server Urls starts //
//export const URL = ' '
//export const GENERICURL ='https://sofc02.cw.solutions/back-office-2.0/graphql'
//export const Auth = ' https://sauth1.cw.solutions/cw-identity/oauth/token'
//export const FILEDOWNLOADURL = 'https://sofc01.cw.solutions:8443/'
// ofc latest server Urls end //

// cwone urls starts //
//export const serverUrl = 'https://s360one.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://sone.mycw.in/back-office-2.0/graphql'
//export const tokenUrl ='https://sone.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sone.mycw.in:8444/'
// cwone urls end //

// s06 urls starts //
//export const serverUrl = 'https://s06core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://s06core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://s06auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s06core.cw.solutions:8444/'
// s06 urls end //

// s05 urls start //
//export const serverUrl = 'https://s05core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://s05core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://s05auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s05core.cw.solutions:8444/'
// s05 urls end //

// s04 urls start //
//export const serverUrl = 'https://s04core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://s04core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://s04auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s04core.cw.solutions:8447/'
// s04 urls end //

// s02 urls start //
//export const serverUrl = 'https://s02core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://s02core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://s02auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s02core.cw.solutions:8445/'
// s02 urls end //

// s03 urls start //
//export const serverUrl = 'https://s03core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://s03core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://s03auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://s03core.cw.solutions:8446/'
// s03 urls end //

// t01 urls start //
//export const serverUrl = 'https://t01core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://t01core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://t01auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://t01core.cw.solutions:8444/'
// t01 urls end //

// cwexceloid urls start //
export const serverUrl = 'https://s360exceloid.mycw.in/cw-retail/graphql'
export const genericUrl ='https://sexceloid.mycw.in/back-office-2.0/graphql'
export const tokenUrl =' https://sexceloid.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sexceloid.mycw.in:8445/'
// cwexceloid urls end //

// t06 urls start //
//export const serverUrl = 'https://t06core.cw.solutions/cw-retail/graphql'
//export const genericUrl ='https://t06core.cw.solutions/back-office-2.0/graphql'
//export const tokenUrl =' https://t06auth.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://t06core.cw.solutions:8443/'
// t06 urls end //
export const UserId = '100'

//export const serverUrl = 'https://s360app.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://sone.mycw.in/back-office-2.0/graphql'
//export const tokenUrl ='https://sone.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sapp.mycw.in:8443/'

// cw test urls start //
//export const serverUrl = 'https://stest.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://stest.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://stest-auth.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://stest.mycw.in:8443/'
// cw test urls end //

// cw dev urls start //
//export const serverUrl = 'https://sdev.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://sdev.mycw.in/back-office-2.0/graphql'
//export const tokenUrl = 'https://sdev-auth.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sdev.mycw.in:8443/'
// cw dev urls end //

// demos2 urls start //
//export const serverUrl = 'https://sdemos2.cwsuite.com/cw-retail/graphql'
//export const genericUrl ='https://sdemos2.cwsuite.com/back-office-2.0/graphql'
//export const tokenUrl = 'https://identity-demos2.cwsuite.com/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sdemos2.cwsuite.com:8443/' 
// demos2 urls end //
